import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Divider, Grid, Link } from '@partner-tech/pax-ui-kit';
import { getUserProfile } from './apiRequests';

const getEmails = emails => (
  <ul>
    {emails.map(email => (
      <li key={email}>
        <Link variant="body2" color="semantic.text.body" href={`mailto:${email}`}>
          {email}
        </Link>
      </li>
    ))}
  </ul>
);

export const AccountExpiredModal = () => {
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile();
        setUserProfile(userProfile);
      } catch (e) {
        window.RetailPortal.Sentry?.captureMessage?.(`User Profile fetch failed: ${e}`);
      }
    };

    fetchUserProfile();
  }, []);

  const getAdministratorContactList = useCallback(() => {
    const { user_roles_per_account: userRolesPerAccount } = userProfile ?? {};
    const adminEmails =
      userRolesPerAccount &&
      userRolesPerAccount.some(({ admin_emails: adminEmails }) => adminEmails.length);

    if (!adminEmails) {
      return (
        <Typography variant="body2" color="semantic.text.body">
          We did not find any registered Retail Center administrator for your company. In order to
          extend your access, please reach out to your buying contact at Zalando and appoint an
          administrator.
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="semantic.text.body" component="span">
        To contact an administrator, reach out to:
        <ul>
          {userRolesPerAccount.map(
            ({ supplier_name: supplierName, admin_emails: emails }, index) => (
              <li key={supplierName + index}>
                {supplierName}: {getEmails(emails)}
              </li>
            )
          )}
        </ul>
      </Typography>
    );
  }, [userProfile]);

  if (!userProfile) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: 'semantic.background.default' }} display="flex">
      <Card
        sx={{
          width: '65%',
          margin: '50px auto'
        }}
      >
        <CardContent>
          <Grid container flexDirection="column" rowGap={3}>
            <Typography variant="body2" color="semantic.text.body" component="span">
              <Typography variant="h3" color="semantic.text.primary" mb={2}>
                Your Retail Center account has expired
              </Typography>
              <p>
                Your account has been temporarily disabled. All accounts must be renewed every 6
                months for security reasons, to ensure only authorized individuals can access
                sensitive business data.
              </p>
              <p>Should you wish to access the Retail Center, please follow these steps:</p>
              <ul>
                <li>
                  <strong>If you have a Supplier Admin user role for all brands</strong> in your
                  organization, please check your email inbox (and your spam folder) for an email
                  with the following subject line: [YOUR ACTION IS REQUIRED] Your Zalando Retail
                  Center account has expired. Follow the procedure to retrieve your access.
                  Alternatively, you can contact another Supplier Admin* in your organization to
                  reactivate your account.
                </li>
                <li style={{ marginTop: '10px' }}>
                  <strong>
                    If you have a Supplier Admin role for only one of multiple brands in your
                    organization OR another user role (Logistics, Order Manager, etc.)
                  </strong>
                  , please ask your Supplier Admin for all brands* to reactivate your account.
                </li>
              </ul>
              <Box mt={3} mb={3}>
                <Divider light />
              </Box>
              <p>Your active Supplier Admin(s) for all brands is / are listed below:</p>
              {getAdministratorContactList()}
              <p>
                * Supplier Admins for all brands who can access their Retail Center account can
                reactivate any other account in their organization by{' '}
                <Link
                  variant="body2"
                  color="semantic.text.body"
                  href="https://help.retail.zalando.com/en/articles/4248109-user-management-how-to-renew-an-expired-account"
                  target="_blank"
                >
                  following the procedure in the User Guide
                </Link>
              </p>
              <Box mt={3} mb={3}>
                <Divider light />
              </Box>
              <p>
                If you still have questions or you&apos;re unsure of your user role, please contact{' '}
                <Link
                  variant="body2"
                  color="semantic.text.body"
                  href="mailto:supplier-support@zalando.de"
                >
                  supplier-support@zalando.de
                </Link>{' '}
                from the email address registered on the Retail Center and explain your situation.
              </p>
              <p>Your Zalando Team</p>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AccountExpiredModal;
