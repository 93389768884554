import React, { useEffect, useReducer } from 'react';
import {
  SNACKBAR_SHOW_EVENT_NAME,
  SNACKBAR_UPDATE_EVENT_NAME,
  SNACKBAR_REMOVE_EVENT_NAME
} from 'core/App/constants/EventNames';
import SnackbarList from './SnackbarList';

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.payload];
    case 'UPDATE':
      return state.map(item => {
        // Return the same items if id doesn't match
        if (item.id !== action.payload.id) {
          return item;
        }
        // Update the item with new payload
        return {
          ...item,
          ...action.payload,
          anchorOrigin: {
            // patch the anchor origin
            ...item.anchorOrigin,
            ...action.payload.anchorOrigin
          }
        };
      });
    case 'REMOVE':
      return state.filter(({ id }) => id !== action.payload);
    default:
      return state;
  }
};

const Snackbar = () => {
  const [state, dispatch] = useReducer(reducer, []);

  const preparePayload = config => {
    // Fill with default values
    const { autoHideDuration = 3000, simple = false, anchorOrigin = {}, ...rest } = config;
    return {
      autoHideDuration,
      simple,
      anchorOrigin: {
        // Apply default anchor origin
        ...defaultAnchorOrigin,
        ...anchorOrigin
      },
      ...rest
    };
  };

  const handleSnackbarAddEvent = config => {
    dispatch({
      type: 'ADD',
      payload: preparePayload(config)
    });
  };

  const handleSnackbarUpdateEvent = config => {
    dispatch({ type: 'UPDATE', payload: config });
  };

  const handleSnackbarRemoveEvent = id => {
    dispatch({ type: 'REMOVE', payload: id });
  };

  // Subscribe to the snackbar event emitter from RetailPortal
  useEffect(() => {
    window.RetailPortal.eventEmitter.on(SNACKBAR_SHOW_EVENT_NAME, handleSnackbarAddEvent);
    window.RetailPortal.eventEmitter.on(SNACKBAR_UPDATE_EVENT_NAME, handleSnackbarUpdateEvent);
    window.RetailPortal.eventEmitter.on(SNACKBAR_REMOVE_EVENT_NAME, handleSnackbarRemoveEvent);
    return () => {
      window.RetailPortal.eventEmitter.off(SNACKBAR_SHOW_EVENT_NAME, handleSnackbarAddEvent);
      window.RetailPortal.eventEmitter.off(SNACKBAR_UPDATE_EVENT_NAME, handleSnackbarUpdateEvent);
      window.RetailPortal.eventEmitter.off(SNACKBAR_REMOVE_EVENT_NAME, handleSnackbarRemoveEvent);
    };
  }, []);

  return <SnackbarList list={state} removeMessage={handleSnackbarRemoveEvent} />;
};

export default Snackbar;
