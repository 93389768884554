import axiosRequest from '../../core/ApiService/request';

// TODO: for now there is a duplication of functionality with core/LegacySharedComponents/authorization.js
//  please take care of it during the clean up of legacy codebase
export const getUserProfile = () => {
  return axiosRequest({
    url: `${AUTH_SERVICE_URL}/api/user-profile`,
    method: 'GET'
  }).then(({ data, status }) => {
    return status !== 200 ? null : data;
  });
};
