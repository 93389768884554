export const pushToAnalytics = ({ category, action, label, value, valueText }) => {
  if (window.UAdataLayer && window.UAdataLayer.push) {
    window.UAdataLayer.push({
      event: 'customEvent',
      category,
      action,
      label,
      value,
      value_text: valueText
    });
  }
};
export const pushToGA4Analytics = ({
  eventName,
  category,
  action,
  label,
  value,
  valueText,
  appName,
  pageContext
}) => {
  const zalando_employee = window && window.zalandoEmployee;

  if (window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({
      event: 'customGA4Event',
      event_name: eventName,
      category,
      action,
      label,
      value,
      value_text: valueText,
      zalando_employee,
      app_name: appName || 'Retail Center',
      ...(pageContext ? { page_context: pageContext } : {})
    });
  }
};

export const trackEvent = trackingData => {
  pushToAnalytics(trackingData);
  pushToGA4Analytics(trackingData);
};
