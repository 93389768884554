import { getUserWithCache } from 'core/LegacySharedComponents/authorization';
import { detectActiveApplication } from '../LegacySharedComponents/utils';
import {
  applicationsGroups,
  allGroups,
  adminApplicationsDescriptions,
  supplierApplicationsDescriptions
} from './constants';

export const getPermittedApps = (appsToFilter, user) => {
  if (!appsToFilter || !user) return [];

  const { permissions = [] } = user;
  const descriptions =
    user.user_type === 'SUPPLIER' ?
      supplierApplicationsDescriptions :
      adminApplicationsDescriptions;

  return appsToFilter
    .filter(app => {
      return permissions.some(permission => {
        return app?.requiredScopes?.includes(permission?.name);
      });
    })
    .map(app => ({
      ...app,
      description: descriptions[app.name]
    }));
};

function isCurrentApplicationInList(applications = [], currentApplication) {
  return applications.some(application => application?.url === currentApplication?.url);
}

export function getAdminAppList(applications = []) {
  const initialValue = allGroups.reduce((acc, group) => ({ ...acc, [group]: [] }), {});

  const groupedApplications = applications.reduce((acc, application) => {
    const groupName = applicationsGroups[application.name];

    if (groupName) {
      const existingApplicationsInGroup = acc[groupName] || [];
      acc[groupName] = [...existingApplicationsInGroup, application];
    }

    return acc;
  }, initialValue);

  return Object.keys(groupedApplications).map(app => ({
    name: app,
    children: groupedApplications[app]
  }));
}

/**
 * Transform application list to required format.
 *
 * Requirement:
 * 1. upto 6 apps to be rendered in menu
 * 2. rest grouped under "More"
 * 3. if "More" has only 1 app, move it under unfoldedApplications list
 * 4. if an app under "More" is selected
 *    4.1 add current app to unfoldedApplications list
 *    4.2 remove current app from foldedApplications / "More" list
 *    4.3 at this point if there is one 1 item in folded list, move it under unfoldedApplications list
 *
 * @param {Array<{name: string, url: string}>} applications
 * @param {Object.<{name: string, url: string}>} currentApplication
 */
export function getSupplierAppList(applications = [], currentApplication) {
  const appsToRender = [];

  // 1. upto 6 apps to be rendered in menu
  const unfoldedApplications = applications.slice(0, 6);
  // 2. rest grouped under "More"
  let foldedApplications = applications.slice(6);

  // 3. if "More" has only 1 app, move it under unfoldedApplications list
  if (foldedApplications.length === 1) {
    unfoldedApplications.push(...foldedApplications);
    foldedApplications = [];
  }

  // 4. if an app under "More" is selected
  if (isCurrentApplicationInList(foldedApplications, currentApplication)) {
    // currently selected app is in the group "More"
    // 4.1 add current app to unfoldedApplications list
    unfoldedApplications.push(currentApplication);

    // 4.2 remove current app from foldedApplications / "More" list
    foldedApplications = foldedApplications.filter(
      application => application.url !== currentApplication.url
    );

    // 4.3 at this point if there is one 1 item in folded list, move it under unfoldedApplications list
    if (foldedApplications.length === 1) {
      unfoldedApplications.push(...foldedApplications);
      foldedApplications = [];
    }
  }

  unfoldedApplications.forEach(({ name, url }) => {
    appsToRender.push({
      name,
      url
    });
  });

  if (foldedApplications.length > 1) {
    const linkToFoldUnder = {
      name: 'More',
      children: foldedApplications.map(({ name, url }) => ({
        name,
        url
      }))
    };

    appsToRender.push(linkToFoldUnder);
  }

  return appsToRender;
}

export const getApplicationsWithDashboard = (applications, user) => {
  const userType = user?.user_type;
  const allAccountsExpired = user?.all_accounts_expired;

  if (!!allAccountsExpired && userType === 'SUPPLIER') {
    return [];
  }

  const dashboardOverview = {
    name: 'Overview',
    url: DASHBOARD_URL
  };

  return [dashboardOverview, ...applications];
};

export const getApplications = () =>
  getUserWithCache().then(user =>
    fetch(`${AUTH_SERVICE_URL}/applications`)
      .then(res => (res.status !== 200 ? null : res.json()))
      .then(applications => {
        const permittedApplications = getPermittedApps(applications, user);
        const applicationsWithDashboard = getApplicationsWithDashboard(permittedApplications, user);
        const currentApplication = detectActiveApplication(applicationsWithDashboard);
        const adminAppList = getAdminAppList(permittedApplications);

        const applicationsList =
          user?.user_type === 'SUPPLIER' ? permittedApplications : adminAppList;

        return {
          applications: applicationsList,
          currentApplication
        };
      })
  );
