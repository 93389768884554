/* eslint-disable */
/* istanbul ignore file */
/* initializes google tag manager */

const dataLayer = [];

const initializeGTM = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' +
      i +
      dl +
      `&gtm_auth=${GOOGLE_TAG_MANAGER_AUTH_STRING}&gtm_preview=env-${GOOGLE_TAG_MANAGER_ENVIRONMENT_ID}&gtm_cookies_win=x`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'UAdataLayer', GOOGLE_TAG_MANAGER_ID);
};

const initializeGA4GTM = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' +
      i +
      dl +
      `&gtm_auth=${GA4_GOOGLE_TAG_MANAGER_AUTH_STRING}&gtm_preview=env-${GA4_GOOGLE_TAG_MANAGER_ENVIRONMENT_ID}&gtm_cookies_win=x`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GA4_GOOGLE_TAG_MANAGER_ID);
};

const init = () => {
  initializeGA4GTM();
  initializeGTM();
};

export default init;
