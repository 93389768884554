import React from 'react';
import { Loader } from '@partner-tech/pax-ui-kit';

const Spinner = () => {
  return (
    <div data-testid="spinner">
      <Loader variant="indeterminate" loading />
    </div>
  );
};

export default Spinner;
