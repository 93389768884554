import React from 'react';
import ReactDOM from 'react-dom';
import AccountExpiredModal from 'components/AccountExpiredModal';
import App from 'core/App';
import { PaxThemeProvider } from '@partner-tech/pax-ui-kit/styles';
import { theme } from 'core/App/theme';

export const mountRetailCenterSharedComponents = async ({ allAccountsExpired }, userName) => {
  const application = document.querySelector('[data-retail-portal-app]');
  const headerTag = document.createElement('div');
  headerTag.id = 'retail-center-header';
  application.parentNode.insertBefore(headerTag, application);

  const link = document.createElement('link');
  link.rel = 'icon';
  link.type = 'image/x-icon';
  link.href = `${UPLOADED_FILES_PATH}/favicon.ico`;
  document.getElementsByTagName('head')[0].appendChild(link);

  const MOUNT_NODE = document.getElementById('retail-center-header');

  function renderToDOM() {
    ReactDOM.render(
      <PaxThemeProvider theme={theme}>
        <App userName={userName} />
        {!!allAccountsExpired && <AccountExpiredModal />}
      </PaxThemeProvider>,
      MOUNT_NODE
    );
  }

  renderToDOM();
};
