/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { AlertDialog } from '@partner-tech/pax-ui-kit';

const defaultModalProps = {
  title: '',
  description: '',
  onCancelText: 'Cancel',
  onSubmitText: 'Ok',
  open: false,
  variant: undefined, // supported variants : success, error, warning, info
  confirmButtonDestructive: false,
  disableEnforceFocus: true,
  displayCloseIcon: false,
  displayDontShowAgain: false,
  onSubmit: () => {},
  onClose: () => {}
};

const Modal = () => {
  const [modalProps, setModalProps] = useState(defaultModalProps);

  const hideModal = () => {
    setModalProps(defaultModalProps);
  };
  const handleShowModal = ({ title, description, onSubmit, variant, ...restProps }) => {
    if (title && description) {
      setModalProps(prev => ({
        ...prev,
        title,
        description,
        onSubmit,
        variant,
        open: true,
        ...restProps
      }));
    }
  };
  // Show the modal based on event emitter from RetailPortal
  useEffect(() => {
    window.RetailPortal.eventEmitter.on('show-modal', handleShowModal);

    return () => {
      window.RetailPortal.eventEmitter.off('show-modal', handleShowModal);
    };
  });

  const handleSubmit = e => {
    if (modalProps.onSubmit) {
      modalProps.onSubmit(e);
    }
    hideModal();
  };

  const handleClose = () => {
    if (modalProps.onClose) {
      modalProps.onClose();
    }
    hideModal();
  };

  return <AlertDialog {...modalProps} onClose={handleClose} onSubmit={handleSubmit} />;
};

export default Modal;
