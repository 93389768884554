/* istanbul ignore file */

const RCApplicationsHotjarProjectMap = [
  {
    urls: ['buying.retail.zalando.com'],
    hotjarId: 2421476
  },
  {
    urls: ['retail-staging.zalando.com'],
    hotjarId: 992709
  },
  {
    urls: ['retail.zalando.com'],
    hotjarId: 992710
  }
];

function getHotjarId() {
  const app = RCApplicationsHotjarProjectMap.find(app =>
    app.urls.some(url => window.location.href.indexOf(url) > -1)
  );
  if (!app) {
    return;
  }
  return app.hotjarId;
}

const initHotjar = () => {
  if (window.hj) {
    return;
  }
  const hjid = getHotjarId();
  if (!hjid) {
    return;
  }
  // eslint-disable-next-line func-names
  window.hj =
    window.hj ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (window.hj.q = window.hj.q || []).push(arguments);
    };
  window._hjSettings = { hjid, hjsv: 6 };
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.async = 1;
  script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
  head.appendChild(script);
};

export default initHotjar;
