import { makeStyles } from '@partner-tech/pax-ui-kit/styles';

export const useStyles = makeStyles({ name: 'SnackbarList' })(theme => ({
  snackBar: {
    position: 'initial',
    margin: theme.spacing(2),
    maxWidth: 'none',
    '-webkit-transform': 'none',
    '-ms-transform': 'none',
    '-moz-transform': 'none',
    transform: 'none',
    // Global styles define color for all H* elements
    color: theme.palette.semantic.ui.content,
    '& h1, h2, h3, h4, h5, h6': {
      color: theme.palette.semantic.ui.content
    }
  },
  smallSnackBarContent: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`, // Decrease the padding for small size snackbar
    '& svg': {
      // For small size snackbar we move icon to the top
      fontSize: '16px !important',
      position: 'relative',
      top: '-14px',
      left: `-${theme.spacing(2)}`
    },
    // For small size snackbar with icon we reserve place for icon with respect to text padding
    '&:has(svg)': {
      paddingLeft: `calc(${theme.spacing(4)} + 16px)`
    },
    // For small size snackbar we move the text to the right
    '& :first-child': {
      paddingLeft: 0,
      '& :first-child': {
        paddingLeft: 0,
        '& :last-child': {
          paddingLeft: 0
        }
      }
    }
  },
  snackBarList: {
    position: 'fixed',
    zIndex: 99999
  },
  bottomSnackBarList: {
    bottom: theme.spacing(2)
  },
  topSnackBarList: {
    top: theme.spacing(2)
  },
  leftSnackBarList: {
    left: theme.spacing(2)
  },
  rightSnackBarList: {
    right: theme.spacing(2)
  },
  centerSnackBarList: {
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)'
  }
}));
