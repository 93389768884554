import eventEmitter from 'core/App/eventEmitter';
import {
  SNACKBAR_SHOW_EVENT_NAME,
  SNACKBAR_UPDATE_EVENT_NAME,
  SNACKBAR_REMOVE_EVENT_NAME
} from './constants/EventNames';

export const isSnackbarConfigValid = (config, isUpdate = false) => {
  // Ensure 'config' exists
  if (!config) return false;

  // either message or isUpdate should be present
  return isUpdate || !!config.message;
};

export const isUpdatedSnackbarConfigValid = config => {
  // Ensure 'config' exists and has an 'id'
  if (!config || !config.id) return false;
  return true;
};

// Use unique id to identify snackbar
let snackbar_id = 0;

const show = (config = {}) => {
  // eslint-disable-next-line no-plusplus
  const id = `sncb_${snackbar_id++}`;

  // Show snackbar only if headline and message are provided
  if (!isSnackbarConfigValid(config)) {
    return '';
  }

  // We put id at the end, so user won't be able to override it
  eventEmitter.emit(SNACKBAR_SHOW_EVENT_NAME, { ...config, id });

  return id;
};

const remove = id => {
  eventEmitter.emit(SNACKBAR_REMOVE_EVENT_NAME, id);
};

const update = config => {
  if (!isUpdatedSnackbarConfigValid(config)) {
    return '';
  }
  eventEmitter.emit(SNACKBAR_UPDATE_EVENT_NAME, config);

  return config.id;
};

export default { show, update, remove };
