export const isPageProtected = (injectedLocation = window.location.href) => {
  const pageProtectedElement = document.querySelector('[data-retail-portal-auth-protected]');

  if (pageProtectedElement) {
    const data = pageProtectedElement.dataset.retailPortalAuthProtected;

    if (!data) {
      return true;
    }

    return new RegExp(data).test(injectedLocation);
  }

  return false;
};
