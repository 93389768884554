import { getLoginUrlWithCache, requestRefreshToken, tokenStorage } from './authorization';
import { refreshTokenWorker } from './utils';

let worker;
const getRandomThreshold = () => {
  const REFRESH_TOKEN_EXPIRY_THRESHOLD_IN_SECONDS = 60;
  const min = (REFRESH_TOKEN_EXPIRY_THRESHOLD_IN_SECONDS - 5) * 1000;
  const max = (REFRESH_TOKEN_EXPIRY_THRESHOLD_IN_SECONDS + 5) * 1000;
  return Math.floor(Math.random() * (max - min)) + min;
};

const getRefreshTokenTimeout = secondsToTokenExpiry => {
  if (!secondsToTokenExpiry) {
    // eslint-disable-next-line no-console
    console.error('Token expiry missing');
    return;
  }
  return secondsToTokenExpiry * 1000 - getRandomThreshold();
};

function loadWebWorker(worker, timeoutInMS) {
  const code = worker.toString();
  const blob = new Blob([`(${code})(${timeoutInMS})`]);
  return new Worker(URL.createObjectURL(blob));
}

function redirectToLogin() {
  const loginUrlPromise = getLoginUrlWithCache(window.location.href);
  loginUrlPromise.then(loginUrl => {
    window.location = loginUrl;
  });
}

const requestAccessTokenThenSetNewRefreshTimeout = () =>
  requestRefreshToken().then(tokenData => {
    if (worker) {
      worker.terminate();
      worker = undefined;
    }
    if (tokenData.access_token) {
      tokenStorage.setToken(tokenData.access_token);
      const timeoutInMS = getRefreshTokenTimeout(tokenData.access_token_expires_in);
      worker = loadWebWorker(refreshTokenWorker, timeoutInMS);
      worker.onmessage = () => {
        // eslint-disable-next-line no-use-before-define
        requestRefreshTokenBasedOnInterval();
      };
    } else {
      redirectToLogin();
    }
  });

const requestRefreshTokenBasedOnInterval = async () => {
  try {
    await requestAccessTokenThenSetNewRefreshTimeout();
  } catch (e) {
    if (worker) {
      worker.terminate();
      worker = undefined;
    }
    window.RetailPortal?.Sentry?.captureException?.(e);
  }
};

export { requestAccessTokenThenSetNewRefreshTimeout };
