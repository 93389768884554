import crypto from 'crypto';
import Cookies from 'js-cookie';
import { addDaysToDate, convertObjectToString, getIDPRequestURL, memorize } from './utils';
import eventEmitter from 'core/App/eventEmitter';

export const ACCESS_TOKEN_KEY = 'RETAIL_ACCESS_TOKEN';
export const CODE_VERIFIER_KEY = 'RETAIL_CODE_VERIFIER_KEY';
export const REDIRECT_URI_ORIGIN_KEY = 'RETAIL_REDIRECT_URI_ORIGIN_KEY';
export const REDIRECT_URI_PATH_KEY = 'RETAIL_REDIRECT_URI_PATH_KEY';
export const APPLICATION_JSON = 'application/json; charset=UTF-8';
export const ID_TOKEN_KEY = 'RETAIL_ID_TOKEN';

// eslint-disable-next-line no-undef
export const getCookieDomain = (host = window.location.host, domain = DOMAIN) => {
  if (host.endsWith(domain)) {
    return domain;
  }

  return '';
};

export const tokenStorage = {
  setToken: (
    token,
    tokenKey = ACCESS_TOKEN_KEY,
    domain = getCookieDomain(),
    expiresIn = addDaysToDate(new Date(), 3)
  ) => {
    Cookies.set(tokenKey, token, {
      expires: expiresIn,
      path: '/',
      domain
    });
  },

  getToken: (tokenKey = ACCESS_TOKEN_KEY) => {
    return Cookies.get(tokenKey);
  },

  deleteToken: (domain = getCookieDomain()) => {
    tokenStorage.setToken('', ACCESS_TOKEN_KEY, domain, new Date(0));
    tokenStorage.setToken('', ID_TOKEN_KEY, domain, new Date(0));
  },

  deleteAuthToken: () => {
    Cookies.remove(ACCESS_TOKEN_KEY, {
      path: '/'
    });
  }
};

export const getTokenFromAuthCode = (
  code,
  codeVerifier,
  redirectOrigin,
  redirectPath,
  http = global.fetch
) => {
  const requestURL = getIDPRequestURL(redirectOrigin + redirectPath, 'access-token');
  return http(`${requestURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': APPLICATION_JSON
    },
    credentials: 'include',
    body: JSON.stringify({ code, codeVerifier, redirectUri: redirectOrigin })
  }).then(response => response.json());
};

const urlBase64 = content => {
  // eslint-disable-next-line no-param-reassign
  content = content.toString('base64');
  const urlSafeReplacements = [
    [/\+/g, '-'],
    [/\//g, '_'],
    [/[=]/g, '']
  ];

  urlSafeReplacements.forEach(([test, replacement]) => {
    // eslint-disable-next-line no-param-reassign
    content = content.replace(test, replacement);
  });

  return content;
};

const createAndStoreCodeVerifier = () => {
  const codeVerifier = urlBase64(crypto.randomBytes(32));
  sessionStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);
  return codeVerifier;
};

const createCodeChallenge = () => {
  const codeVerifier = createAndStoreCodeVerifier();
  const hash = crypto.createHash('sha256').update(codeVerifier).digest();
  return urlBase64(hash);
};

export const getLoginUrl = (
  redirect_uri = window.location.href,
  state = '',
  http = global.fetch,
  codeChallenge = createCodeChallenge
) => {
  const { origin, pathname, search, hash } = new URL(redirect_uri);
  const redirectUriPath = pathname + search + hash;
  sessionStorage.setItem(REDIRECT_URI_ORIGIN_KEY, origin);
  sessionStorage.setItem(REDIRECT_URI_PATH_KEY, redirectUriPath);

  const requestURL = getIDPRequestURL(redirect_uri, 'identity-server-params');
  return http(`${requestURL}`, {
    credentials: 'include'
  })
    .then(response => response.json())
    .then(({ login: { query_params: queryParams, identity_server_url: loginUrl } }) => {
      const updatedQueryParams = {
        ...queryParams,
        ...(!!state && { state }),
        redirect_uri: encodeURIComponent(origin),
        code_challenge: codeChallenge(),
        code_challenge_method: 'S256'
      };
      return `${loginUrl}?${convertObjectToString(updatedQueryParams, '&')}`;
    });
};

export const getLoginUrlWithCache = memorize(getLoginUrl);

export const requestRefreshToken = (http = global.fetch, token = tokenStorage.getToken()) =>
  token ?
    http(`${AUTH_SERVICE_URL}/api/refresh-tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': APPLICATION_JSON,
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        if (response.status === 401) {
          return Promise.resolve(response);
        }
        return Promise.resolve(response.json());
      }) :
    Promise.reject(new Error('No Token is found!'));

export const getUser = (http = global.fetch, token = tokenStorage.getToken()) =>
  token ?
    http(`${AUTH_SERVICE_URL}/api/userinfo`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => (response.status === 401 ? null : response.json())) :
    Promise.resolve(null);

export const getUserWithCache = memorize(getUser);

export const getUserHash = (http = global.fetch, token = tokenStorage.getToken()) =>
  token ?
    http(`${AUTH_SERVICE_URL}/api/user-hash`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => response.json()) :
    Promise.resolve(null);

export const getUserAdmins = (http = global.fetch, token = tokenStorage.getToken()) =>
  http(`${AUTH_SERVICE_URL}/api/user-profile`, {
    method: 'GET',
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`
    }
  }).then(response => response.json());

export const getUserAdminsWithCache = memorize(getUserAdmins);

export const getLogoutUrl = (
  redirectUri = window.location.href,
  http = global.fetch,
  localTokenStorage = tokenStorage
) => {
  const requestURL = `${AUTH_SERVICE_URL}/api/identity-server-params`;
  const idToken = localTokenStorage.getToken(ID_TOKEN_KEY);
  const logoutRedirectUri = new URL(redirectUri);
  const logoutQueryParams = convertObjectToString(
    {
      post_logout_redirect_uri: encodeURIComponent(`${logoutRedirectUri.origin}`),
      id_token_hint: idToken
    },
    '&'
  );

  return http(`${requestURL}`, {
    credentials: 'include'
  })
    .then(response => response.json())
    .then(({ logout: { identity_server_url: logoutUrl } }) => {
      return `${logoutUrl}?${logoutQueryParams}`;
    });
};

export const getLogoutUrlWithCache = memorize(getLogoutUrl);

export const logout = (
  windowRedirect = url => {
    window.location = url;
  },
  tokenStorageInstance = tokenStorage,
  getLogoutUrlInstance = getLogoutUrl,
  eventEmitterInstance = eventEmitter
) => {
  if (window.Intercom) {
    try {
      // Triggering a shutdown on intercom so as to let it know when user has logged out.
      // This is used to clear intercom's session cookie which also locks down the help articles.
      window.Intercom('shutdown');
    } catch (e) {
      window.RetailPortal.Sentry?.captureMessage('Shutdown intercom failed');
    }
  }

  eventEmitterInstance.emit('logout');
  getLogoutUrlInstance().then(url => {
    tokenStorageInstance.deleteToken();
    windowRedirect(url);
  });
};
