import axios, { CancelToken } from 'axios';

const axiosRequest = ({ url, method, data = {}, ...rest } = {}) => {
  const source = CancelToken.source();
  const config = {
    method,
    url,
    data,
    cancelToken: source.token
  };

  const accessToken = window.RetailPortal.getToken();

  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`
    };
    config.withCredentials = true;
  }

  const updatedConfig = {
    ...config,
    ...rest
  };

  const requestPromise = axios(updatedConfig);
  requestPromise.cancel = () => source.cancel();

  return requestPromise;
};

export default axiosRequest;
