import * as Sentry from '@sentry/browser';

const RCApplicationsSentryProjectMap = [
  {
    urls: [
      'register.retail-staging.zalando.com/app/user-management',
      'register.retail.zalando.com/app/user-management'
    ],
    dsn: 'https://2a987e74ebd4444fbe1b7d6496ebb05c@sentry.stups.zalan.do/2559'
  },
  {
    urls: ['webinvoice.retail-staging.zalando.com', 'webinvoice.retail.zalando.com'],
    dsn: 'https://c9071c3fe7ba46a5a0ee197150984a42@sentry.stups.zalan.do/1011'
  },
  {
    urls: ['shipping-notice.retail-staging.zalando.com', 'shipping-notice.retail.zalando.com'],
    dsn: 'https://85bb80c7392d47d9a70aa93cae93fa43@sentry.stups.zalan.do/1012'
  },
  {
    urls: [
      'buying-articles-release.retail-staging.zalando.com',
      'buying-articles.retail.zalando.com'
    ],
    dsn: 'https://d2a029f4a4644ac19927c5a3f1d96826@sentry.stups.zalan.do/1211'
  },
  {
    urls: ['buying.retail-staging.zalando.com', 'buying.retail.zalando.com'],
    dsn: 'https://1b1f5aeefcd14992970ca0ef91d53fc6@sentry.stups.zalan.do/2121'
  },
  {
    urls: [
      'order-test.retail-staging.zalando.com',
      'order-release.retail-staging.zalando.com',
      'order.retail.zalando.com'
    ],
    dsn: 'https://58ee81992eaf4d0e8ca11301007d2b74@sentry.stups.zalan.do/1573'
  },
  {
    urls: ['search.retail-staging.zalando.com', 'search.retail.zalando.com'],
    dsn: 'https://f930f3be18174f0f82df65e46f93242c@sentry.stups.zalan.do/2437'
  },
  {
    urls: ['pointblank.retail-staging.zalando.com', 'pointblank.retail.zalando.com'],
    dsn: 'https://61e93dbc06cb4e6aa2e486918a0b6c77@sentry.stups.zalan.do/2475'
  },
  {
    urls: [
      'material-management.retail-staging.zalando.com',
      'material-management.retail.zalando.com'
    ],
    dsn: 'https://834a715884e648ebacc38e78af1619ff@sentry.stups.zalan.do/2481'
  },
  {
    urls: ['customs.retail-staging.zalando.com', 'customs.retail.zalando.com'],
    dsn: 'https://0dadd353dbc744d494c78c45e28653d9@sentry.stups.zalan.do/2572'
  },
  {
    urls: [
      'bill-of-material-management.retail-staging.zalando.com',
      'bill-of-material-management.retail.zalando.com'
    ],
    dsn: 'https://8b4a8802555144bb83b1a21bc160657b@sentry.stups.zalan.do/2484'
  },
  {
    urls: ['start.retail-staging.zalando.com', 'start.retail.zalando.com'],
    dsn: 'https://f538abc9360346b9b100091e0fc34352@sentry.stups.zalan.do/2388'
  },
  {
    urls: ['retail-partner.retail-staging.zalando.com', 'retail-partner.retail.zalando.com'],
    dsn: 'https://e8205336e96b493e969f9928b2e4b9b9@sentry.stups.zalan.do/2553'
  },
  {
    urls: [
      'product-compliance-test.retail-staging.zalando.com',
      'product-compliance.retail-staging.zalando.com',
      'product-compliance.retail.zalando.com'
    ],
    dsn: 'https://c525afbbe05c472cbe4c964a36f71b09@sentry.stups.zalan.do/2554'
  },
  {
    urls: [
      'condition-agreement.retail-staging.zalando.com',
      'condition-agreement.retail.zalando.com'
    ],
    dsn: 'https://62bff440b96a4784a8e2d8df7f4e9fe3@sentry.stups.zalan.do/2557'
  },
  {
    urls: ['org-structure.retail-staging.zalando.com', 'org-structure.retail.zalando.com'],
    dsn: 'https://92370182a6f24b908e729719b8852697@sentry.stups.zalan.do/2558'
  },
  {
    urls: [
      'supplier-configuration-frontend.retail-operations-test.zalan.do',
      'supplier-configuration-frontend.retail-operations.zalan.do'
    ],
    dsn: 'https://d60e5c51b1a047b8aec19282cd2b9328@sentry.stups.zalan.do/2560'
  },
  {
    urls: [
      'onboarding.retail-staging.zalando.com/supplier-onboarding/',
      'onboarding.retail.zalando.com/supplier-onboarding/'
    ],
    dsn: 'https://d9eea376389e47898ce6b522d114983b@sentry.stups.zalan.do/2563'
  }
];

const getSentryDSNFromAppURL = () => {
  const app = RCApplicationsSentryProjectMap.find(app =>
    app.urls.some(url => window.location.hostname === url)
  );
  if (!app) {
    return;
  }
  return app.dsn;
};

const generateSentryUserID = () => {
  return `sentry_${Math.random().toString(36).substr(2, 10)}_${Date.now()}`;
};

const getSentryUserID = () => {
  try {
    const sentryIDfromLocalStorage = window.localStorage.getItem('sentryUserID');
    if (!sentryIDfromLocalStorage) {
      const sentryUserID = generateSentryUserID();
      window.localStorage.setItem('sentryUserID', sentryUserID);
      return sentryUserID;
    }
    return sentryIDfromLocalStorage;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return generateSentryUserID();
  }
};

const initSentry = () => {
  if (ENVIRONMENT !== 'production' && ENVIRONMENT !== 'staging') {
    return;
  }

  const sentryDSN = getSentryDSNFromAppURL();
  if (!sentryDSN) {
    return;
  }

  Sentry.init({
    dsn: sentryDSN,
    environment: ENVIRONMENT,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ]
  });

  Sentry.configureScope(scope => {
    scope.setUser({ id: getSentryUserID() });
  });

  return Sentry;
};

export default initSentry;
