/* eslint-disable no-console */
import './polyfills';
import initSentry from 'core/App/initSentry';
import initAnalytics from 'core/App/initAnalytics';
import initHotjar from 'core/App/initHotjar';
import initIntercom from 'core/App/initIntercom';
import { notifyError } from 'core/App/notifyError';
import snackbar from 'core/App/snackbar';

import eventEmitter from 'core/App/eventEmitter';
import {
  CODE_VERIFIER_KEY,
  getLoginUrlWithCache,
  getTokenFromAuthCode,
  getUserHash,
  getUserWithCache,
  ID_TOKEN_KEY,
  logout,
  REDIRECT_URI_ORIGIN_KEY,
  REDIRECT_URI_PATH_KEY,
  tokenStorage
} from 'core/LegacySharedComponents/authorization';
import { getApplications } from 'core/App/getApplications';
import { convertStringToObject, deleteParametersFromURL } from 'core/LegacySharedComponents/utils';
import { requestAccessTokenThenSetNewRefreshTimeout } from 'core/LegacySharedComponents/requestAccessTokenThenSetNewRefreshTimeout';
import { mountRetailCenterSharedComponents } from 'core/MountRetailCenterSharedComponents';
import { isPageProtected } from 'core/App/isPageProtected';

const Sentry = initSentry();
initHotjar();
// imperative PAX Modal API
const Modal = {
  show: modalConfigs => {
    eventEmitter.emit('show-modal', modalConfigs);
  },
  info: modalConfigs => {
    eventEmitter.emit('show-modal', { ...modalConfigs, variant: 'info' });
  },
  success: modalConfigs => {
    eventEmitter.emit('show-modal', { ...modalConfigs, variant: 'success' });
  },
  warning: modalConfigs => {
    eventEmitter.emit('show-modal', { ...modalConfigs, variant: 'warning' });
  },
  error: modalConfigs => {
    eventEmitter.emit('show-modal', { ...modalConfigs, variant: 'error' });
  },
  confirm: modalConfigs => {
    Modal.show(modalConfigs);
  }
};

const RetailPortal = {
  getLoginUrl: getLoginUrlWithCache,
  getToken: () => tokenStorage.getToken(),
  getUser: getUserWithCache,
  eventEmitter,
  getAnalytics: () => {
    const removedMethodWarning = `RetailPortal.getAnalytics method has been removed. Analytics events should be setup in Google Tag Manager.
    This is a no-op.`;
    console.warn(removedMethodWarning);
    return () => {
      console.warn(removedMethodWarning);
    };
  },
  snackbar,
  notifyError,
  getApplications,
  Sentry,
  logout,
  Modal
};

const postponeEventCall = (event, ...args) =>
  setTimeout(() => RetailPortal.eventEmitter.emit(event, ...args), 0);

/**
 * this is used to handle redirection from keycloak
 *
 location
  {
    "href": "http://localhost:8081/#state=&session_state=051301b6&code=9452",
    "ancestorOrigins":{},
    "origin":"http://localhost:8081",
    "protocol":"http:",
    "host":"localhost:8081",
    "hostname":"localhost",
    "port":"8081",
    "pathname":"/",
    "search":"",
    "hash":"#state=&session_state=051301b6&code=9452"
  }

  urlData
  {
    state: ""
    session_state: "9592"
    code: "051301b6"
  }
*/

export const getAuthCodeFromUrl = (
  location = window.location,
  localTokenStorage = tokenStorage
) => {
  const urlData = convertStringToObject(location.hash.slice(1), '&');
  if (urlData.code) {
    const redirectUrl = sessionStorage.getItem(REDIRECT_URI_ORIGIN_KEY);
    const redirectPath = sessionStorage.getItem(REDIRECT_URI_PATH_KEY);
    deleteParametersFromURL(null, null, redirectUrl + redirectPath);
    return getTokenFromAuthCode(
      urlData.code,
      sessionStorage.getItem(CODE_VERIFIER_KEY),
      redirectUrl,
      redirectPath
    ).then(tokenData => {
      localTokenStorage.setToken(tokenData.access_token);
      if (tokenData.id_token) {
        localTokenStorage.setToken(tokenData.id_token, ID_TOKEN_KEY);
      }

      return urlData.code;
    });
  }
  if (urlData.error) {
    return Promise.reject(urlData);
  }

  return Promise.resolve(null);
};

const initializeIntercom = ({ name, email, user_type }, user_hash) => {
  try {
    if (!user_hash) {
      // if intercom user hash is not available, do not initialize intercom
      throw new Error();
    }
    initIntercom({ name, email, user_type, user_hash });
  } catch (e) {
    // capture error in sentry if user hash is not available
    window.RetailPortal.Sentry?.captureMessage?.('Unable to fetch user hash');
  }
};

export const handleAuthorizedPage = () => {
  if (isPageProtected()) {
    const loginUrlPromise = getLoginUrlWithCache(window.location.href);
    return Promise.all([requestAccessTokenThenSetNewRefreshTimeout(), getUserWithCache()])
      .then(async ([, user]) => {
        if (user) {
          const userHashResult = await getUserHash();
          const { user_hash: userHash, user_name: userName } = userHashResult;
          initializeIntercom(user, userHash);
          initAnalytics();

          const allAccountsExpired = !!user.all_accounts_expired && user.user_type === 'SUPPLIER';

          return { shouldRender: true, allAccountsExpired, userName };
        }
        return { shouldRender: false, loginUrlPromise };
      })
      .catch(error => {
        window.RetailPortal.Sentry?.captureMessage?.(`Unable to fetch user data: ${error}`);
        return { shouldRender: false, loginUrlPromise };
      });
  }
  // if page is not protected, initialize analytics without waiting any further
  initAnalytics();
  return Promise.resolve({ shouldRender: true });
};

const isPageInteractive = () => ['interactive'].indexOf(document.readyState) !== -1;
const waitForDomReady = new Promise(resolve => {
  if (isPageInteractive()) {
    resolve();
  } else {
    window.document.addEventListener('readystatechange', () => isPageInteractive() && resolve());
  }
});
const waitForPageLoad = new Promise(resolve => window.addEventListener('load', resolve));

const trackUserData = async () => {
  const user = await getUserWithCache();

  if (!user) return;

  try {
    let supplierCodeToTrack;
    let roleNameToTrack;
    if (user.user_type === 'SUPPLIER') {
      const supplierCodes = {};
      const roleNames = {};
      const userPermissions = user?.permissions || [];

      userPermissions.forEach(({ suppliers, roleName }) => {
        suppliers.forEach(({ supplier_code: supplierCode }) => {
          if (!supplierCodes[supplierCode]) {
            supplierCodes[supplierCode] = supplierCode;
          }
        });
        if (!roleNames[roleName]) {
          roleNames[roleName] = roleName;
        }
      });

      supplierCodeToTrack = Object.keys(supplierCodes).join('; ');
      roleNameToTrack = Object.keys(roleNames).join('; ');
    } else {
      supplierCodeToTrack = 'All';
    }
    const dataToPush = {
      userType: user.user_type,
      supplierCodes: supplierCodeToTrack
    };

    if (roleNameToTrack) {
      dataToPush.roleNames = roleNameToTrack;
    }
    // sending data to UA (Universal Analytics)
    // eslint-disable-next-line no-unused-expressions
    UAdataLayer && UAdataLayer.push(dataToPush);
    // sending data to GA4 (Google Analytics 4)
    // eslint-disable-next-line no-unused-expressions
    dataLayer && dataLayer.push(dataToPush);
  } catch (e) {
    console.error('Unable to push to GA', e);
  }
};

export const handleAuthAndRenderApp = (authCode, waitForPageLoad) => {
  return handleAuthorizedPage()
    .then(({ shouldRender, loginUrlPromise, allAccountsExpired, userName }) => {
      if (shouldRender) {
        waitForPageLoad.then(() => {
          if (!allAccountsExpired) {
            postponeEventCall('application-ready');
          }
          const path = sessionStorage.getItem(REDIRECT_URI_PATH_KEY);
          if (path && !window.location.href.includes(path)) {
            sessionStorage.removeItem(REDIRECT_URI_PATH_KEY);
            window.location.replace(window.location.origin + path);
          }
          mountRetailCenterSharedComponents({ allAccountsExpired }, userName);

          if (authCode) {
            postponeEventCall('login');
          }
          trackUserData();
        });
      } else {
        return loginUrlPromise.then(loginUrl => {
          window.location = loginUrl;
        });
      }
    })
    .catch(error => {
      document.write('Unable to authenticate the user. Please try again later!');
      console.log(error);
    });
};

const handleMissingAuthCodeErrorCase = (err, waitForPageLoad) => {
  waitForPageLoad.then(() => {
    const errorParagraph = document.createElement('p');
    errorParagraph.innerHTML = `Error: ${err.error}`;
    document.body.appendChild(errorParagraph);
    if (err.error_description) {
      const descriptionParagraph = document.createElement('p');
      descriptionParagraph.innerHTML = `Description: ${err.error_description}`;
      document.body.appendChild(descriptionParagraph);
    }
  });
};

export function main() {
  Promise.all([getAuthCodeFromUrl(), waitForDomReady])
    .then(([authCode]) => handleAuthAndRenderApp(authCode, waitForPageLoad))
    .catch(err => handleMissingAuthCodeErrorCase(err, waitForPageLoad));
}

main();

window.RetailPortal = RetailPortal;
