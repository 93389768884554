// disable all the transitions and animations in Pax theme when reduce motion is enabled
export const theme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @media (prefers-reduced-motion: reduce) {
              *: {
                transition: initial;
                animation: initial;
                transition-delay: 0s !important;
                transition-duration: 0s !important;
                animation-delay: -0.0001s !important;
                animation-duration: 0s !important;
                animation-play-state: paused !important;
              }
            }
          `
    }
  }
};
