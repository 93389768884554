const PURCHASING = 'Purchasing';
const SHIPMENT_AND_PAYMENT = 'Shipment & Payment';
const MANAGEMENT = 'Management';

export const allGroups = [PURCHASING, SHIPMENT_AND_PAYMENT, MANAGEMENT];

const BUYING_HOME = 'Buying Home';
const ARTICLES = 'Articles';
const ORDER = 'Orders';
const WEB_REORDER = 'Web Reorder';

const SHIPPING_NOTICES = 'Shipping Notices';
const INVOICES = 'Invoices';
const POINTBLANK = 'Point Blank';
const CUSTOMS_IMPORT = 'Customs Import';
const IMPORT_DUTY = 'Import Duty';

const CO_PLANNER = 'Co Planner';
const PERFORMANCE_INSIGHTS = 'Performance Insights';
const COMPAZ = 'Compaz';
const ZMS_INSIGHTS = 'ZMS Insights';
const USER_MANAGEMENT = 'User Management';
const DOCUMENTS = 'Documents';
const CATEGORY_MANAGEMENT_MASTER_DATA = 'Category Management Master Data';
const RETAIL_PARTNER = 'Retail Partner';
const CONDITION_AGREEMENT = 'Condition Agreement';
const MATERIAL_MANAGEMENT = 'Material Management';
const BILL_OF_MATERIALS = 'Bill of Materials';
const SUSTAINABILITY_CERTIFICATES = 'Sustainability Certificates';
const SAFETY_TESTING = 'Safety Testing';
const QUALITY_INSPECTIONS = 'Quality Inspections';

export const applicationsGroups = {
  [BUYING_HOME]: PURCHASING,
  [ARTICLES]: PURCHASING,
  [ORDER]: PURCHASING,
  [WEB_REORDER]: PURCHASING,

  [SHIPPING_NOTICES]: SHIPMENT_AND_PAYMENT,
  [INVOICES]: SHIPMENT_AND_PAYMENT,
  [POINTBLANK]: SHIPMENT_AND_PAYMENT,
  [CUSTOMS_IMPORT]: SHIPMENT_AND_PAYMENT,
  [IMPORT_DUTY]: SHIPMENT_AND_PAYMENT,

  [CO_PLANNER]: MANAGEMENT,
  [PERFORMANCE_INSIGHTS]: MANAGEMENT,
  [COMPAZ]: MANAGEMENT,
  [ZMS_INSIGHTS]: MANAGEMENT,
  [USER_MANAGEMENT]: MANAGEMENT,
  [DOCUMENTS]: MANAGEMENT,
  [CATEGORY_MANAGEMENT_MASTER_DATA]: MANAGEMENT,
  [RETAIL_PARTNER]: MANAGEMENT,
  [CONDITION_AGREEMENT]: MANAGEMENT,
  [MATERIAL_MANAGEMENT]: MANAGEMENT,
  [BILL_OF_MATERIALS]: MANAGEMENT,
  [SUSTAINABILITY_CERTIFICATES]: MANAGEMENT,
  [SAFETY_TESTING]: MANAGEMENT,
  [QUALITY_INSPECTIONS]: MANAGEMENT
};

export const adminApplicationsDescriptions = {
  [BUYING_HOME]: 'Place orders',
  [ARTICLES]: 'Manage article information',
  [ORDER]: 'Manage and confirm purchase orders',
  [WEB_REORDER]: 'Manage and confirm reorders',
  [SHIPPING_NOTICES]: 'Check and view shipping notices',
  [CUSTOMS_IMPORT]: 'Check and view Customs Imports',
  [IMPORT_DUTY]: 'Check and view Import Duty',
  [INVOICES]: 'Manage paid and unpaid invoices',
  [POINTBLANK]: 'Manage invoices and solve accounting clarification cases',
  [CO_PLANNER]: 'Monitor partner performance',
  [PERFORMANCE_INSIGHTS]: 'Monitor your articles performance',
  [COMPAZ]: 'Category Operations dashboards',
  [ZMS_INSIGHTS]: 'Check your performance and onsite metrics',
  [USER_MANAGEMENT]: 'Invite new users, manage their roles',
  [CATEGORY_MANAGEMENT_MASTER_DATA]:
    'Manage the OrgStructure, article groups and employee assignment',
  [DOCUMENTS]: 'Access important documents and templates',
  [RETAIL_PARTNER]: 'Activate and deactivate suppliers',
  [CONDITION_AGREEMENT]: 'Manage condition agreements',
  [MATERIAL_MANAGEMENT]: 'Manage the Raw Materials data that Suppliers use to product articles',
  [BILL_OF_MATERIALS]: 'Link Materials to the Articles and management of Material consumption',
  [SUSTAINABILITY_CERTIFICATES]:
    'Collection of the certificates to back the Sustainability claims made on the articles',
  [SAFETY_TESTING]:
    'Safety tasks creation and management by nominating articles and updating the test statuses',
  [QUALITY_INSPECTIONS]: 'Nominate articles for quality checks and manage inspection results'
};

export const supplierApplicationsDescriptions = {
  [ARTICLES]: 'Manage your articles',
  [ORDER]: 'Check order statuses and confirm your orders',
  [WEB_REORDER]: 'Manage and confirm reorders',
  [SHIPPING_NOTICES]: 'Manage your deliveries to Zalando',
  [CUSTOMS_IMPORT]: 'Manage your Customs Import to Zalando',
  [IMPORT_DUTY]: 'Manage your Import Duty to Zalando',
  [INVOICES]: 'Manage paid and unpaid invoices',
  [CO_PLANNER]: 'Monitor the performance of your brands',
  [PERFORMANCE_INSIGHTS]: 'Monitor your articles performance',
  [ZMS_INSIGHTS]: 'Check your performance and onsite metrics',
  [USER_MANAGEMENT]: 'Invite new users, manage their roles',
  [DOCUMENTS]: 'Access important documents and templates',
  [RETAIL_PARTNER]: 'Activate and deactivate suppliers',
  [MATERIAL_MANAGEMENT]: 'Manage the Raw Materials data that Suppliers use to product articles',
  [BILL_OF_MATERIALS]: 'Link Materials to the Articles and management of Material consumption',
  [SUSTAINABILITY_CERTIFICATES]:
    'Collection of the certificates to back the Sustainability claims made on the articles',
  [SAFETY_TESTING]:
    'Safety tasks creation and management by nominating articles and updating the test statuses',
  [QUALITY_INSPECTIONS]: 'Nominate articles for quality checks and manage inspection results'
};
