/* istanbul ignore next */
import React from 'react';
import ReactDOM from 'react-dom';

export const appendSpacerToBody = () => {
  try {
    const el = document.createElement('div');
    // The spacer now has a static class name. The downstream apps can now modify it's styles if required.
    ReactDOM.render(
      <div className="retail-center-intercom-spacer" style={{ height: '80px' }} />,
      el
    );

    document.body.appendChild(el);
  } catch (e) {
    console.error('Unable to add spacer div', e);
  }
};

const initIntercom = (userInfo = {}) => {
  // eslint-disable-next-line no-undef
  const APP_ID = INTERCOM_APP_ID;

  window.intercomSettings = {
    app_id: APP_ID,
    email: userInfo.email,
    user_hash: userInfo.user_hash
  };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  window.Intercom('boot', {
    app_id: APP_ID,
    ...userInfo
  });

  appendSpacerToBody();
};

export default initIntercom;
